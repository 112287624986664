* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f7f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/light/Roboto-Light.eot');
  src: url('./assets/fonts/light/Roboto-Light.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/light/Roboto-Light.woff2') format('woff2'),
    url('./assets/fonts/light/Roboto-Light.woff') format('woff'),
    url('./assets/fonts/light/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/light_italic/Roboto-LightItalic.eot');
  src: url('./assets/fonts/light_italic/Roboto-LightItalic.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/light_italic/Roboto-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/light_italic/Roboto-LightItalic.woff') format('woff'),
    url('./assets/fonts/light_italic/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/regular/Roboto-Regular.eot');
  src: url('./assets/fonts/regular/Roboto-Regular.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/regular/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/regular/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/regular/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/italic/Roboto-Italic.eot');
  src: url('./assets/fonts/italic/Roboto-Italic.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/italic/Roboto-Italic.woff2') format('woff2'),
    url('./assets/fonts/italic/Roboto-Italic.woff') format('woff'),
    url('./assets/fonts/italic/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/medium/Roboto-Medium.eot');
  src: url('./assets/fonts/medium/Roboto-Medium.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/medium/Roboto-Medium.woff2') format('woff2'),
    url('./assets/fonts/medium/Roboto-Medium.woff') format('woff'),
    url('./assets/fonts/medium/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/medium_italic/Roboto-MediumItalic.eot');
  src: url('./assets/fonts/medium_italic/Roboto-MediumItalic.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/medium_italic/Roboto-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/medium_italic/Roboto-MediumItalic.woff') format('woff'),
    url('./assets/fonts/medium_italic/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/bold/Roboto-Bold.eot');
  src: url('./assets/fonts/bold/Roboto-Bold.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/bold/Roboto-Bold.woff2') format('woff2'),
    url('./assets/fonts/bold/Roboto-Bold.woff') format('woff'),
    url('./assets/fonts/bold/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/bold_italic/Roboto-BoldItalic.eot');
  src: url('./assets/fonts/bold_italic/Roboto-BoldItalic.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/bold_italic/Roboto-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/bold_italic/Roboto-BoldItalic.woff') format('woff'),
    url('./assets/fonts/bold_italic/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/** Typography */

h1, h2, h3, h4, h5, h6 {
  color: #04449d;
  font-weight: 500;
  letter-spacing: 0;
}
